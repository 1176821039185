import React from 'react';
import Spinner from '../../components/bootstrap/Spinner';

const LoadingSpinners = () => {
	return (
		<>
			<Spinner isGrow size={12} />
			<Spinner isGrow size={12} className='mx-3' />
			<Spinner isGrow size={12} />
		</>
	);
};

export default LoadingSpinners;
