import React, { useState } from 'react';
import { useMeasure, useWindowSize } from 'react-use';
import { useQuery } from '@apollo/client';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import classNames from 'classnames';
import { GET_COMPANY_SERVICES } from '../../graphQL/companyInfoQueries';
import { mainPagesMenu, contactPage } from '../../menu.Cyberstorm';
import SocialMediaButton from '../../components/SocialMediaButton';
import csFullLogo from '../../assets/cyberstormFullLogo.png';
import visaLogo from '../../assets/footer/visa.png';
import masterLogo from '../../assets/footer/master.png';
import AELogo from '../../assets/footer/americanExpress.png';
import paypalLogo from '../../assets/footer/paypal.png';

const Footer = () => {
	const [ref, { height }] = useMeasure();
	const windowsWidth = useWindowSize().width;
	const navigate = useNavigate();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	const [companyServices, setCompanyServices] = useState([]);

	const { loading: LoadingGetCompanyServices } = useQuery(GET_COMPANY_SERVICES, {
		fetchPolicy: 'cache-and-network',
		async onCompleted({ getCompanyServices }) {
			setCompanyServices(getCompanyServices);
		},
	});

	const mainPagesMenuArray = [
		mainPagesMenu.whatWeDoPage,
		mainPagesMenu.ourWorkPage,
		mainPagesMenu.aboutPage,
		mainPagesMenu.careersPage,
		contactPage.contactPage,
	];

	const CompanyBrand = (
		<div
			className={classNames('d-flex flex-column fs-4', {
				'text-end': windowsWidth >= 500,
				'text-center': windowsWidth < 500,
			})}>
			<img src={csFullLogo} alt='cyberstorm Full Logo' className='footer-full-logo' />
			<span className='fs-16 mt-4'> © 2023 CyberStorm Asia. </span>
			<span className='fs-16'> All rights reserved </span>
		</div>
	);

	return (
		<footer ref={ref} className='footer cs-footer bg-dark'>
			<div className='container-fluid'>
				<div
					className={classNames('d-flex flex-wrap my-5', {
						'justify-content-between': windowsWidth >= 500,
						'justify-content-center': windowsWidth < 500,
					})}>
					<div
						className={classNames('d-flex flex-column fs-4', {
							'w-75': windowsWidth >= 500 && windowsWidth < 786,
							'w-100 text-center': windowsWidth < 500,
						})}>
						<span className='fs-18 fw-bold'> Services </span>
						{companyServices.length > 0 && !LoadingGetCompanyServices && (
							<div className='d-flex flex-column mt-4 mb-5'>
								{companyServices.map((service) => (
									<Button
										key={service.serviceName}
										className={classNames('footer-text lh-lg', {
											'text-start': windowsWidth >= 500,
											'text-center': windowsWidth < 500,
										})}
										onClick={() => {
											navigate({
												pathname: mainPagesMenu.whatWeDoPage.path,
												search: `${createSearchParams({
													work: service.serviceName,
												})}`,
											});
										}}>
										{service.serviceName}
									</Button>
								))}
							</div>
						)}
					</div>

					<div
						className={classNames('d-flex flex-column fs-4', {
							'w-25': windowsWidth >= 500 && windowsWidth < 786,
							'w-100 text-center': windowsWidth < 500,
						})}>
						<span className='fs-18 fw-bold'> Company </span>
						<div className='d-flex flex-column mt-4 mb-5'>
							{mainPagesMenuArray.map((page) => (
								<Button
									key={page.id}
									className={classNames('footer-text lh-lg', {
										'text-start': windowsWidth >= 500,
										'text-center': windowsWidth < 500,
									})}
									onClick={() => {
										navigate({
											pathname: page.path,
										});
									}}>
									{page.text}
								</Button>
							))}
						</div>
					</div>

					<div
						className={classNames('d-flex flex-column fs-4', {
							'w-100 text-center': windowsWidth < 500,
						})}>
						<span className='fs-18 fw-bold'> Connect </span>
						<div
							className={classNames('d-flex mt-4 mb-5', {
								'justify-content-center': windowsWidth < 500,
							})}>
							<SocialMediaButton
								icon='Linkedin'
								link='https://www.linkedin.com'
								className='me-4'
							/>
							<SocialMediaButton
								icon='Twitter'
								link='https://twitter.com'
								className='me-4'
							/>
							<SocialMediaButton
								icon='Facebook'
								link='https://www.facebook.com'
								className='me-4'
							/>
							<SocialMediaButton
								icon='Instagram'
								link='https://www.instagram.com'
								className='me-4'
							/>
							<SocialMediaButton icon='Youtube' link='https://www.youtube.com' />
						</div>
						<div
							className={classNames('d-flex mt-4 mb-5', {
								'justify-content-center': windowsWidth < 500,
							})}>
							<img src={visaLogo} alt='Visa' className='me-4 payment-logo' />
							<img src={masterLogo} alt='Mastercard' className='me-4 payment-logo' />
							<img
								src={AELogo}
								alt='American Express'
								className='me-4 payment-logo'
							/>
							<img src={paypalLogo} alt='PayPal' className='me-4 payment-logo' />
						</div>
						{windowsWidth > 786 && windowsWidth <= 1100 && CompanyBrand}
					</div>
					{(windowsWidth <= 786 || windowsWidth > 1100) && CompanyBrand}
				</div>
			</div>
		</footer>
	);
};

export default Footer;

