import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { HeaderRight } from '../../../layout/Header/Header';
import HeaderRightContent from './HeaderRightContent';
interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	return (
		<HeaderRight>
			<div className='row g-3 d-flex align-items-center justify-content-center'>
				{beforeChildren}

				<HeaderRightContent/>

				{afterChildren}
			</div>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
