import React, { useState } from 'react';
import whatsappLogo from '../../Cyberstorm/assets/whatsapp.png';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_INFO } from '../graphQL/companyInfoQueries';
import LoadingSpinners from './LoadingSpinners';

const WhatsAppIcon = () => {
	const [whatsapp, setWhatsapp] = useState([]);

	const { loading: LoadingGetContactInfo } = useQuery(GET_COMPANY_INFO, {
		fetchPolicy: 'cache-and-network',
		async onCompleted({ getCompanyInfo }) {
			setWhatsapp(getCompanyInfo[0].whatsapp);
		},
	});

	const handleWhatsappClick = () => {
		const message = encodeURIComponent('Hello, I would like to get in touch with you.');
		const url = `https://api.whatsapp.com/send?phone=${whatsapp}&text=${message}`;
		window.open(url, '_blank');
	};

	return (
		<>
			{LoadingGetContactInfo ? (
				<div className='mt-5'>
					<LoadingSpinners />
				</div>
			) : (
				<div className='whatsapp-icon' onClick={handleWhatsappClick}>
					<img src={whatsappLogo} />
				</div>
			)}
		</>
	);
};

export default WhatsAppIcon;
