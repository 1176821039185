import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../components/icon/Icon';

const SocialMediaButton = ({ className, icon, link }) => {
	return (
		<a href={link} className={className} target='_blank' rel='noreferrer'>
			<Icon icon={icon} size='3x' className='social-media-icons' />
		</a>
	);
};

export default SocialMediaButton;

SocialMediaButton.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
};

SocialMediaButton.defaultProps = {
	className: '',
};
