import React, { Suspense, useEffect, useState } from 'react';
import Card, { CardBody } from '../../components/bootstrap/Card';
import PageWrapper from '../PageWrapper/PageWrapper';
import Page from '../Page/Page';
import SubHeader from '../SubHeader/SubHeader';
import ContentRoutes from './ContentRoutes';
import Button from '../../components/bootstrap/Button';
import WhatsAppIcon from '../../Cyberstorm/components/Whatsapp';

const LOADING = (
	<PageWrapper>
		<SubHeader>
			<div />
		</SubHeader>
		<Page>
			<div className='row h-100'>
				<div className='col-lg-6'>
					<Card stretch>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
				<div className='col-lg-6'>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	</PageWrapper>
);

const Content = () => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const handleScrollUpBtnVisibility = () => {
			window.pageYOffset > 100 ? setShow(true) : setShow(false);
		};
		window.addEventListener('scroll', handleScrollUpBtnVisibility);
		return () => {
			window.removeEventListener('scroll', handleScrollUpBtnVisibility);
		};
	}, []);

	return (
		<main className='content'>
			<Suspense fallback={LOADING}>
				<ContentRoutes />
				<Button
					icon='ArrowUpward'
					onClick={() => {
						window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
					}}
					className='scrollIntoTopBtn bg-info text-light'
					style={{
						display: show ? 'block' : 'none',
					}}
				/>
				<WhatsAppIcon />
			</Suspense>
		</main>
	);
};

export default Content;
