import gql from 'graphql-tag';

export const GET_COMPANY_INFO = gql`
	query GetCompanyInfo {
		getCompanyInfo {
			_id
			phones
			generalEmail
			infoEmail
			salesEmail
			addresses
			whatsapp
		}
	}
`;

export const GET_COMPANY_SERVICES = gql`
	query GetCompanyServices {
		getCompanyServices {
			_id
			serviceName
			serviceImage
			serviceDescription
			serviceDesSummary
		}
	}
`;

export const GET_COMPANY_WORKS = gql`
	query GetCompanyWorks {
		getCompanyWorks {
			_id
			workName
			workDescription
			workThumbnail
			workLogoImg
			workColorCode
			serviceCatogary
			isRecent
		}
	}
`;

export const GET_COMPANY_WORK_BY_ID = gql`
	query GetCompanyWorkByID($workID: ID!) {
		getCompanyWorkByID(workID: $workID) {
			_id
			workName
			workTitle
			workDescription
			workThumbnail
			workLogoImg
			workColorCode
			serviceCatogary
			isRecent
			moreInfo {
				subTitle
				subDescription
				subImageURL
			}
		}
	}
`;

