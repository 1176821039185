import React, { lazy } from 'react';
import { homePage, mainPagesMenu, contactPage, ourWorkInfoPage, defaultPagesMenu } from '../menu.Cyberstorm';

const MAIN_PAGES = {
	HOME_PAGE: lazy(() => import('../pages/HomePage')),
	WHAT_WE_DO_PAGE: lazy(() => import('../pages/WhatWeDoPage')),
	OUR_WORK_PAGE: lazy(() => import('../pages/OurWorkPage')),
	OUR_WORK_INFO_PAGE: lazy(() => import('../pages/OurWorkInfoPage')),
	ABOUT_PAGE: lazy(() => import('../pages/AboutPage')),
	CAREER_PAGE: lazy(() => import('../pages/CareerPage')),
	CONTACT_PAGE: lazy(() => import('../pages/ContactPage')),
};

const DEFAULT = {
	PAGE_404: lazy(() => import('../pages/Page404')),
	IN_DEVELOPMENT: lazy(() => import('../pages/InDevelopmentPage')),
};

const mainPages = [
	{
		path: homePage.homePage.path,
		element: <MAIN_PAGES.HOME_PAGE />,
		exact: true,
	},
	{
		path: mainPagesMenu.whatWeDoPage.path,
		element: <MAIN_PAGES.WHAT_WE_DO_PAGE />,
		exact: true,
	},
	{
		path: mainPagesMenu.ourWorkPage.path,
		element: <MAIN_PAGES.OUR_WORK_PAGE />,
		exact: true,
	},
	{
		path: ourWorkInfoPage.ourWorkInfoPage.path,
		element: <MAIN_PAGES.OUR_WORK_INFO_PAGE />,
		exact: true,
	},
	{
		path: mainPagesMenu.aboutPage.path,
		element: <MAIN_PAGES.ABOUT_PAGE />,
		exact: true,
	},
	{
		path: mainPagesMenu.careersPage.path,
		element: <MAIN_PAGES.CAREER_PAGE />,
		exact: true,
	},
	{
		path: contactPage.contactPage.path,
		element: <MAIN_PAGES.CONTACT_PAGE />,
		exact: true,
	},
];

const defaultPages = [
	{
		path: defaultPagesMenu.page404.path,
		element: <DEFAULT.PAGE_404 />,
		exact: true,
	},
	{
		path: defaultPagesMenu.inDevelopment.path,
		element: <DEFAULT.IN_DEVELOPMENT />,
		exact: true,
	},
];

const contentRoutes = [...mainPages, ...defaultPages];

export default contentRoutes;
