import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import Button from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import Icon from '../../../components/icon/Icon';
import { mainPagesMenu, contactPage } from '../../menu.Cyberstorm';

const HeaderRightContent = () => {
    const navigate = useNavigate();
	const windowsWidth = useWindowSize().width;

    const mainPagesMenuArray = [
		mainPagesMenu.whatWeDoPage,
		mainPagesMenu.ourWorkPage,
		mainPagesMenu.aboutPage,
		mainPagesMenu.careersPage,
		contactPage.contactPage,
	];

	return (
		<div className='col-auto'>
			<div className='d-flex flex-wrap align-items-center justify-content-center'>
				{mainPagesMenuArray.map((page) => (
					<Button
						key={page.id}
						className='navButton'
						onClick={() => {
							navigate({
								pathname: page.path,
							});
						}}>
						{windowsWidth <= 900 ? (
							<Popovers trigger='hover' desc={page.text.toUpperCase()}>
								<Icon icon={page.icon} size='lg' className='' />
							</Popovers>
						) : (
							page.text.toUpperCase()
						)}
					</Button>
				))}
			</div>
		</div>
	);
};

export default HeaderRightContent;
