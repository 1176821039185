/* ************************************************************** */
/* ************** This file is part of the cyberstorm **************** */
/* ************************************************************** */

import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { ToastProvider } from 'react-toast-notifications';
import ReactGA from 'react-ga4';
import ThemeContext from '../contexts/themeContext';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import '../Cyberstorm/styles/index.scss';
import '../Cyberstorm/fonts/isidoraSans/IsidoraSans-Bold.ttf';
import '../Cyberstorm/fonts/isidoraSans/IsidoraSans-SemiBold.ttf';
import '../Cyberstorm/fonts/isidoraSans/IsidoraSans-Medium.ttf';
import '../Cyberstorm/fonts/isidoraSans/IsidoraSans-MediumItalic.ttf';

const App = () => {
	getOS();

	ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	// create http link for BE
	const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			console.log(`[GraphQL error]: ${graphQLErrors}`);
		}
		if (networkError) {
			console.log(`[Network error]: ${networkError}`);
		}
	});

	// initialize apollo client
	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link: from([errorLink, httpLink]),
	});

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESIGN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	return (
		<ApolloProvider client={client}>
			<ThemeProvider theme={theme}>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
							zIndex: fullScreenStatus ? 1 : undefined,
							overflow: fullScreenStatus ? 'scroll' : undefined,
						}}>
						<Wrapper />
					</div>
					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</ToastProvider>
			</ThemeProvider>
		</ApolloProvider>
	);
};

export default App;

