import React from 'react';
import DefaultHeader from '../layout/Header/DefaultHeader';

const header = [
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default header;
