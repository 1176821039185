export const homePage = {
	homePage: {
		id: 'homePage',
		text: 'Home',
		path: '/',
		icon: 'HouseFill',
		description:
			'We are CyberStormAsia that is an energetic Tech Team. Cyber Storm asia that work for you. CyberStorm Asia build Human-Centered digital solutions.',
	},
};

export const mainPagesMenu = {
	whatWeDoPage: {
		id: 'whatWeDoPage',
		text: 'What We Do',
		path: '/whatWeDo',
		icon: 'Binoculars',
		description:
			'Cibar Strom Asia is a digital product and experience agency that brings your ideas to life. From web and mobile development to UX design, we create digital solutions that help businesses thrive. Contact us today to learn more.',
	},
	ourWorkPage: {
		id: 'ourWorkPage',
		text: 'Our Work',
		path: '/ourWork',
		icon: 'ClipboardData',
		description:
			'CyberStrom delivers impact solutions through static & dynamic websites, e-commerce integrations, full-stack web apps, mobile development, cloud hosting, and dedicated teams. Contact us to grow your business.',
	},
	aboutPage: {
		id: 'aboutPage',
		text: 'About',
		path: '/about',
		icon: 'InfoCircle',
		description:
			'Cyber Storm is an energetic tech team and top-ranked software company in Australia and Japan. We build human-centered digital solutions that work for you. Contact us today.',
	},
	careersPage: {
		id: 'careersPage',
		text: 'Careers',
		path: '/careers',
		icon: 'FileEarmarkPerson',
		description:
			'Join the young and energetic tech team at CyberStorm to make a big impact on your career. Our respectful and open atmosphere fosters growth and success. Apply today.',
	},
};

export const contactPage = {
	contactPage: {
		id: 'contactPage',
		text: 'Contacts',
		path: '/contact',
		icon: 'Headset',
		description:
			"Ready to build something amazing? Let us know about your digital products, services, etc. Ciber stone love to dedicate for our customers' success. CyberStorm Warakapola is most grooming IT startup 2022 among the all other IT companies sri lanka",
	},
};

export const ourWorkInfoPage = {
	ourWorkInfoPage: {
		id: 'ourWorkInfoPage',
		text: 'Our Work Info',
		path: '/ourWorkInfo',
		icon: 'ClipboardData',
		description:
			"Ready to build something amazing? Let us know about your digital products, services, etc. Ciber storm love to dedicate for our customers' success. Cyber storm Warakapola is the best Software development company sri lanka and Mobile development company sri lanka as a IT startup 2022",
	},
};

export const defaultPagesMenu = {
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '/404',
		icon: 'ReportGmailerrorred',
		description:
			"Ready to build something amazing? Let us know about your digital products, services, etc. We love to dedicate for our customers' success.",
	},
	inDevelopment: {
		id: 'InDevelopment',
		text: 'In Development',
		path: '/inDevelopment',
		icon: 'ReportGmailerrorred',
		description: 'CyberStorm is developing this page.',
	},
};
