import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header, { HeaderLeft } from './Header';
import { homePage } from '../../menu.Cyberstorm';
import Button from '../../../components/bootstrap/Button';
import CommonHeaderRight from './CommonHeaderRight';
import csFullLogo from '../../assets/cyberstormFullLogo.png'

const DefaultHeader = () => {
	const navigate = useNavigate();

	return (
		<Header>
			<HeaderLeft>
				<Button
					className='fs-3 ps-0 border-0 mx-2'
					onClick={() => {
						navigate({
							pathname: homePage.homePage.path,
						});
					}}>
						<img
							src={csFullLogo}
							alt='cyberstorm Full Logo'
							className='full-logo'
						/>
				</Button>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
